import React from 'react';
import './Contacts.less'

const Contacts = () => {
  return (
    <div className='msgs-holder'>
      <div className="msgs-holder__wrapper">
        <div className="msgs-holder__column">
          <h2>г. Юрга</h2>
          <div className="msgs-holder__item">
            <h4 className="msgs-holder__title">Центральный офис</h4>
            <p>Кемеровская область, г. Юрга, ул. Павлова, д. 7</p>
            <p>E-mail: <a href="mailto:kpkedinstvo@kpkedinstvo.ru">kpkedinstvo@kpkedinstvo.ru</a>,</p>
            <p><a href="mailto:kpkedinstvo@mail.ru">kpkedinstvo@mail.ru</a></p>
            <p>Телефон приемной: <a href="tel:(38451) 42-0-42">(38451) 42-0-42</a></p>
            <p className='msgs-holder__bold'>Режим работы офиса:</p>
            <p>пн.-чт.: с 9.30 до 17.30, перерыв: с 13.30 до 14.30</p>
            <p>пт.: с 9.30 до 16.30, перерыв: с 13.30 до 14.30</p>
            <p>сб.: с 9.00 до 13.00, вс. - выходной</p>
          </div>
          <div className="msgs-holder__item">
            <h4 className="msgs-holder__title">Офис №2</h4>
            <p>г. Юрга, ул. Волгоградская, д. 17</p>
            <p>E-mail: <a href="mailto:ofis_yurga@kpkedinstvo.ru">ofis_yurga@kpkedinstvo.ru</a></p>
            <p>Телефон отдела выдачи займов: <a href="tel:(38451) 42-3-70">(38451) 42-3-70</a></p>
            <p className='msgs-holder__bold'>Режим работы офиса:</p>
            <p>пн.-чт.: с 9.30 до 17.30, перерыв: с 13.30 до 14.30</p>
            <p>пт.: с 9.30 до 16.30, перерыв: с 13.30 до 14.30</p>
            <p>сб., вс. - выходной</p>
          </div>
        </div>

        <div className="msgs-holder__column">
          <h2>Доп.офис г. Болотное</h2>
          <div className="msgs-holder__item">
            <h4 className="msgs-holder__title">Офис №3</h4>
            <p>Новосибирская область, г. Болотное, ул.</p>
            <p>Забобонова, д. 20</p>
            <p>Email: <a href="mailto:bolotnoe@kpkedinstvo.ru">bolotnoe@kpkedinstvo.ru</a>,</p>
            <p><a href="mailto:bolotnoe@kpkedinstvo.ru">edinstvo_bol@mail.ru</a>,</p>
            <p>Телефон: <a href="tel:(38349) 24-7-26">(38349) 24-7-26</a></p>
            <p className='msgs-holder__bold'>Режим работы офиса:</p>
            <p>пн.-чт.: с 9.30 до 17.30, перерыв: с 13.30 до 14.30</p>
            <p>пт.: с 9.30 до 16.30, перерыв: с 13.30 до 14.30</p>
            <p>сб.: с 9.00 до 13.00, вс. - выходной</p>
          </div>
        </div>

        <div className="msgs-holder__column">
          <h2>Доп.офис пгт. Яшкино</h2>
          <div className="msgs-holder__item">
            <h4 className="msgs-holder__title">Офис №4</h4>
            <p>Кемеровская область, пгт. Яшкино, ул. Куйбышева, д. 26</p>
            <p>E-mail: <a href="mailto:yashkino@kpkedinstvo.ru">yashkino@kpkedinstvo.ru</a>,</p>
            <p><a href="mailto:edinstvo_yahkino@mail.ru">edinstvo_yahkino@mail.ru</a></p>
            <p>Телефон: <a href="tel:(38455) 52-8-34">(38455) 52-8-34</a></p>
            <p className='msgs-holder__bold'>Режим работы офиса:</p>
            <p>пн.-чт.: с 9.30 до 17.30</p>
            <p>пт.: с 9.30 до 16.30</p>
            <p>сб., вс. - выходной</p>
          </div>
        </div>

        <div className="msgs-holder__column">
          <h2>Доп.офис г. Тайга</h2>
          <div className="msgs-holder__item">
            <h4 className="msgs-holder__title">Офис №5</h4>
            <p>Кемеровская область, г.Тайга, ул. 40 лет Октября, д. 8</p>
            <p>E-mail: <a href="mailto:tayga@kpkedinstvo.ru">tayga@kpkedinstvo.ru</a>,</p>
            <p><a href="mailto:edinstvo_tayga@mail.ru">edinstvo_tayga@mail.ru</a></p>
            <p>Телефон: <a href="tel:(38448) 24-5-63">(38448) 24-5-63</a></p>
            <p className='msgs-holder__bold'>Режим работы офиса:</p>
            <p>пн.-чт.: с 9.30 до 17.30, перерыв: с 13.30 до 14.30</p>
            <p>пт.: с 9.30 до 16.30, перерыв: с 13.30 до 14.30</p>
            <p>сб., вс. - выходной</p>
          </div>
        </div>

        <div className="msgs-holder__column">
          <h2>Доп.офис г. Анжеро-Судженск</h2>
          <div className="msgs-holder__item">
            <h4 className="msgs-holder__title">Офис №6</h4>
            <p>Кемеровская область, г. Анжеро-Судженск, ул. Ленина, д. 7, оф. 217</p>
            <p>E-mail: <a href="mailto:angero-sudgensk@kpkedinstvo.ru">angero-sudgensk@kpkedinstvo.ru</a>,</p>
            <p><a href="mailto:edinstvo_ang@mail.ru">edinstvo_ang@mail.ru</a></p>
            <p>Телефон: <a href="tel:(38453) 65-0-46">(38453) 65-0-46</a></p>
            <p className='msgs-holder__bold'>Режим работы офиса:</p>
            <p>пн.-чт.: с 9.30 до 17.30, перерыв: с 13.30 до 14.30</p>
            <p>пт.: с 9.30 до 16.30, перерыв: с 13.30 до 14.30</p>
            <p>сб., вс. - выходной</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
